import React from "react";
import { Row, Col } from "react-bootstrap";
import { IoIosQuote } from "react-icons/io";

interface IQuoteBlock {
  quoteText: string;
  author: string;
  authorPosition: string;
  clientLogoUrl: string;
}

export const QuoteBlock = (props: IQuoteBlock) => {
  return (
    <div className="quote">
      <Row>
        <Col lg={9}>
          <div className="quote__icon">
            <IoIosQuote size={30} />
          </div>
          <h5 className="quote__text">{props.quoteText}</h5>
          <span className="quote__rating">★★★★★</span>
          <div className="quote__footer">
            <p className="text-accent">
              <strong>{props.author}</strong>
            </p>
            <p>
              <small>{props.authorPosition}</small>
            </p>
          </div>
        </Col>
        <Col
          lg={3}
          className="d-flex flex-column justify-content-center align-items-center pb-3"
        >
          <img
            className="rounded-circle quote__image"
            src={props.clientLogoUrl}
          />
        </Col>
      </Row>
    </div>
  );
};
