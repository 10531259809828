import React from "react";
import { Footer } from "../global/footer";

interface Props {}

const LayoutCaseLanding: React.FC<Props> = props => {
  return (
    <div className="layout--case-landing">
      {props.children}
      <Footer />
    </div>
  );
};

export default LayoutCaseLanding;
