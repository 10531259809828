import React from "react";
import LayoutCaseLanding from "../components/layouts/layout-case-landing";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { Link, useStaticQuery, graphql } from "gatsby";
import SEO from "../components/shared/seo";
import { QuoteBlock } from "../components/shared/quote-block";
import { Helmet } from "react-helmet";

function CaseStudiesLanding(props): React.FC<any> {
  const projectImages = useStaticQuery(graphql`
    query {
      energyAction: file(relativePath: { eq: "card-energyaction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Pindan: file(relativePath: { eq: "card-pindan.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pindanLogo: file(relativePath: { eq: "pindan-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuyLogo: file(relativePath: { eq: "visibuy-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      visibuy: file(relativePath: { eq: "visibuy-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eaLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      murdoch: file(relativePath: { eq: "card-murdoch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      murdochLogo: file(relativePath: { eq: "murdoch-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmers: file(relativePath: { eq: "card-wesfarmers.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wesfarmersLogo: file(relativePath: { eq: "wesfarmers-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohnLogo: file(relativePath: { eq: "st-john-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      energyActionLogo: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      stJohnVas: file(relativePath: { eq: "card-vas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stJohn: file(relativePath: { eq: "card-stjohn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      investTradeLogo: file(relativePath: { eq: "invest-trade-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      investTrade: file(relativePath: { eq: "invest-trade-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wesfarmersSustainability: file(relativePath: { eq: "wesfarmers-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      murdochCourse: file(relativePath: { eq: "Murdoch-case-study-tile.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "4"
            },
            "review": [
              {
                "@type": "Review",
                "author": "Michael Johnson",
                "datePublished": "2020-03-09",
                "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
                "reviewRating": {
                  "@type": "Rating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "worstRating": "1"
                }
              },
              {
                "@type": "Review",
                "author": "Wesfarmers Corporate Affairs",
                "datePublished": "2020-03-09",
                "reviewBody": "Diviv have been nothing but professional, responsive, helpful and proactive since we started working with them. Highly recommend for corporate website support and service.",
                "reviewRating": {
                  "@type": "Rating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "worstRating": "1"
                }
              },
              {
                "@type": "Review",
                "author": "Simon Dutton",
                "datePublished": "2020-03-09",
                "reviewBody": "The team’s efforts to get us to a position of Go Live with our News Portal was exceptional. Your hard work and diligence has been pivotal to get us over the line on this one with very tight timeframes. Thanks guys.",
                "reviewRating": {
                  "@type": "Rating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "worstRating": "1"
                }
              },
              {
                "@type": "Review",
                "author": "StJohn WA",
                "datePublished": "2020-03-09",
                "reviewBody": "We have been working with Diviv for around 4 months now and have been very pleased with how they operate. Biggest task to date has been a new brand campaign which meant a new look website. The guys delivered on time and on budget which is what you need when the pressure is on. We hope to continue to work with them for many more years.",
                "reviewRating": {
                  "@type": "Rating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "worstRating": "1"
                }
              }
            ]
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Our Work"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseLanding>
        <div
          id="case-study-landing-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <section className="introduction">
            <Container>
              <Row>
                <Col sm={12} md={12} lg={10} xl={8}>
                  <h6>Our Work</h6>
                  <h2>People ignore design that ignores people</h2>
                </Col>
              </Row>
            </Container>
          </section>

          <Container>
            <Row>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/wesfarmers-sustainability"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.wesfarmersSustainability.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.wesfarmersLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Wesfarmers Sustainability</small>
                    <h5>Sitefinity Custom Development</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/murdoch-course"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.murdochCourse.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.murdochLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Murdoch Course Page</small>
                    <h5>Sitefinity Custom Development</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/investtrade"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.investTrade.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.investTradeLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Invest and Trade</small>
                    <h5>Sitefinity CMS Build</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/visibuy"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.visibuy.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.visibuyLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Pharmacy 777</small>
                    <h5>Visibuy Website</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/pindan"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.Pindan.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.pindanLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Pindan</small>
                    <h5>Kentico to Wordpress Migration</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/energy-action"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.energyAction.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={
                        projectImages.energyActionLogo.childImageSharp.fluid.src
                      }
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Energy Action</small>
                    <h5>Client Portal Rebuild</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/wesfarmers"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.wesfarmers.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={
                        projectImages.wesfarmersLogo.childImageSharp.fluid.src
                      }
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Wesfarmers</small>
                    <h5>Support & Development</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/stjohn-ambulance/volunteer-accounting-system"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.stJohnVas.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.stJohnLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">St John Ambulance</small>
                    <h5>Volunteer Accounting System (VAS)</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/stjohn-ambulance/beats"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.stJohn.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.stJohnLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">St John Ambulance</small>
                    <h5>BEATS Internal News Site</h5>
                  </div>
                </Link>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Link
                  to="/our-work/murdoch-university"
                  className="case-carousel__item mb-5"
                >
                  <div
                    className="case-carousel__item__background background-image"
                    style={{
                      backgroundImage: `url('${projectImages.murdoch.childImageSharp.fluid.src}')`,
                    }}
                  ></div>
                  <div className="client-logo">
                    <img
                      src={projectImages.murdochLogo.childImageSharp.fluid.src}
                    />
                  </div>
                  <div className="case-carousel__item__inner">
                    <small className="client-name">Murdoch University</small>
                    <h5>Support, Development and Integration</h5>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="quote-wrapper pb-5">
          <Container>
            <Carousel>
              <Carousel.Item>
                <QuoteBlock
                  author="Michael Johnson"
                  authorPosition="Senior Business Analyst"
                  quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                  clientLogoUrl={projectImages.eaLogo.childImageSharp.fluid.src}
                />
              </Carousel.Item>
              <Carousel.Item>
                <QuoteBlock
                  author="Wesfarmers"
                  authorPosition="Corporate Affairs"
                  quoteText="Diviv have been nothing but professional, responsive, 
                            helpful and proactive since we started working with them. 
                            Highly recommend for corporate website support and service."
                  clientLogoUrl={
                    projectImages.wesfarmersLogo.childImageSharp.fluid.src
                  }
                />
              </Carousel.Item>
              <Carousel.Item>
                <QuoteBlock
                  author="Simon Dutton"
                  authorPosition="Project Manager"
                  quoteText="The team’s efforts to get us to a position of Go
                                  Live with our News Portal was exceptional. Your
                                  hard work and diligence has been pivotal to get us
                                  over the line on this one with very tight
                                  timeframes. Thanks guys."
                  clientLogoUrl={
                    projectImages.murdochLogo.childImageSharp.fluid.src
                  }
                />
              </Carousel.Item>
              <Carousel.Item>
                <QuoteBlock
                  author="StJohn WA"
                  authorPosition="Corporate Affairs"
                  quoteText="We have been working with Diviv for around 4 months
                             now and have been very pleased with how they operate. 
                             Biggest task to date has been a new brand campaign which 
                             meant a new look website. The guys delivered on time and 
                             on budget which is what you need when the pressure is on. 
                             We hope to continue to work with them for many more years."
                  clientLogoUrl={
                    projectImages.stJohnLogo.childImageSharp.fluid.src
                  }
                />
              </Carousel.Item>
            </Carousel>
          </Container>
        </section>
      </LayoutCaseLanding>
    </>
  );
}

export default CaseStudiesLanding;
